
import { defineComponent } from 'vue'
import BaseIcon from '@/shared/components/base-icon/BaseIcon.vue'
import Datepicker from '@vuepic/vue-datepicker'
import Multiselect from '@vueform/multiselect'
import router from '@/router'
import moment from 'moment'
import { debounce } from 'lodash'
import FilterPanelGhost from '@/shared/components/filter/FilterPanelGhost.vue'
export default defineComponent({
  components: { FilterPanelGhost, BaseIcon, Datepicker, Multiselect },
  props: {
    filters: Array
  },
  emits: ['fetchData'],
  data () {
    return {
      form: {} as any,
      formHasValue: false,
      sort: null,
      firstInitialization: true
    }
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler (val) {
        this.initializeForm(val)
      }
    },
    form: {
      deep: true,
      handler (val) {
        this.validateForm(val)
        this.setItemsToRoute(val)
      }
    }
  },
  methods: {
    onExpand (item: any): void {
      if (item.dataRequested) return
      this.$emit('fetchData', item.actionName)
      item.dataRequested = true
    },
    clearMonth (label: string): void {
      const dates = this.form.dates
      Object.assign(dates, { [label + '_month']: null })
      Object.assign(dates, { [label + '_year']: null })
      this.form.dates = dates
    },

    initializeForm (val: any, reset = false): void {
      val.forEach((el: any) => {
        if (el.type === 'checkbox') {
          const targetValue = el.data.map((checkItem: any) => {
            return {
              value: checkItem, checked: false
            }
          })
          Object.assign(this.form, { [el.model]: targetValue })
        } else if (el.type === 'order') {
          Object.assign(this.form, { orderBy: el.default ? el.default : null })
          Object.assign(this.form, { sort: 'desc' })
        } else {
          Object.assign(this.form, { [el.model]: null })
        }
      })
      this.setUrlValuesToForm(reset)
      this.validateForm(this.form)
    },

    resetForm: function (): void {
      const query = this.$route.query
      if (query.pageNumber) {
        query.pageNumber = '1'
      }
      router.replace({ query, replace: false })
      this.initializeForm(this.filters, true)
    },
    checkIfAnySelectedCheckbox (arr: any): boolean {
      let hasCheckedValue = false
      arr.forEach((el: any) => {
        if (el.checked) {
          hasCheckedValue = true
        }
      })
      return hasCheckedValue
    },
    setItemsToRoute (form: any): void {
      const updatedRoute = JSON.parse(JSON.stringify(this.$route.query))
      const routeParams: any = {}
      Object.keys(form).forEach((el: any) => {
        if (Array.isArray(form[el])) {
          if (form[el].length) {
            // eslint-disable-next-line no-prototype-builtins
            if (form[el][0].hasOwnProperty('checked')) {
              const ids = form[el].filter((arrayItem: any) => arrayItem.checked).map((arrayItem: any) => arrayItem.value.id)
              if (ids.length) {
                // router.push({ query: { [el]: ids } })
                Object.assign(routeParams, { [el]: `arry:${ids.join(',')}` })
              } else {
                Object.assign(routeParams, { [el]: undefined })
              }
              // eslint-disable-next-line no-prototype-builtins
            } else if (!form[el][0].hasOwnProperty('checked')) {
              Object.assign(routeParams, { [`${el}_from`]: form[el][0] ? moment(form[el][0]).format('YYYY-MM-DDTHH:mm:ss') : undefined })
              Object.assign(routeParams, { [`${el}_to`]: form[el][1] ? moment(form[el][1]).format('YYYY-MM-DDTHH:mm:ss') : undefined })
            }
          }
        } else {
          // eslint-disable-next-line no-prototype-builtins
          if (form[el] && form[el].hasOwnProperty('month') && form[el].hasOwnProperty('year') && Object.keys(form[el]).length === 2) {
            Object.assign(routeParams, { [`${el}_month`]: form[el].month ? form[el].month + 1 : undefined })
            Object.assign(routeParams, { [`${el}_year`]: form[el].year ? form[el].year : undefined })
            // eslint-disable-next-line no-prototype-builtins
          } else if (form[el] && form[el].hasOwnProperty('hours') && form[el].hasOwnProperty('minutes') && form[el].hasOwnProperty('seconds')) {
            Object.assign(routeParams, { [el]: `${form[el].hours}:${form[el].minutes}:${form[el].seconds}` ? `${form[el].hours}:${form[el].minutes}:${form[el].seconds}` : undefined })
          } else if (form[el] && moment.isDate(form[el])) {
            Object.assign(routeParams, { [el]: moment(form[el]).format('YYYY-MM-DDThh:mm:ss') ? moment(form[el]).format('YYYY-MM-DDThh:mm:ss') : undefined })
          } else if (form[el] && typeof form[el] === 'object') {
            Object.assign(routeParams, { [el]: form[el].id ? form[el].id : undefined })
          } else {
            Object.assign(routeParams, { [el]: form[el] ? form[el] : undefined })
          }
        }
      })
      const diffArry: boolean[] = []
      this.componentValidParams.forEach((el: any) => {
        if (!routeParams[el]) {
          diffArry.push(true)
        } else {
          diffArry.push((updatedRoute[el] === routeParams[el]))
        }
      })
      if (diffArry.includes(false)) {
        if (updatedRoute.pageNumber) {
          updatedRoute.pageNumber = '1'
        }
      }
      router.replace({ query: { ...updatedRoute, ...routeParams }, replace: false })
    },

    setUrlValuesToForm (reset = false): void {
      const query: any = JSON.parse(JSON.stringify(this.$route.query))

      Object.keys(query).forEach((param: any) => {
        if (reset) {
          if (this.componentValidParams.includes(param.split('_')[0])) {
            Object.assign(query, { [param]: undefined })
          }
        }
      })
      // set query params to form
      if (!reset) {
        const dateRangeVal: any = []
        Object.keys(query).forEach((param: any) => {
          if (this.componentValidParams.includes(param.split('_')[0]) && query[param]) {
            if (param.split('_')[param.split('_').length - 1] === 'from') {
              dateRangeVal.push(query[param])
            } else if (param.split('_')[param.split('_').length - 1] === 'to') {
              dateRangeVal.push(query[param])
              dateRangeVal.push(param.split('_')[0])
            } else if (param.split('_')[param.split('_').length - 1] === 'month') {
            } else if (param.split('_')[param.split('_').length - 1] === 'year') {
            } else if (typeof query[param] === 'string' && query[param].startsWith('arry:')) {
              query[param].split(':')[1].split(',').forEach((checkParam: any) => {
                this.form[param].map((checkItem: any) => {
                  if (checkItem.value.id.toString() === checkParam) {
                    checkItem.checked = true
                  }
                })
              })
            } else if (param === 'orderBy') {
              this.form.orderBy = query.orderBy
              this.form.sort = query.sort
            } else {
              this.form[param] = query[param]
            }
          }
        })
        if (dateRangeVal.length && dateRangeVal[0] !== '') {
          this.form[dateRangeVal[2]] = [
            moment(dateRangeVal[0]).format('YYYY-MM-DDThh:mm:ss[Z]'),
            moment(dateRangeVal[1]).format('YYYY-MM-DDThh:mm:ss[Z]')
          ]
        }
      }
      setTimeout(() => {
        router.replace({ query, replace: true })
      }, 0)

      // }
    },
    validateForm (val: any): void {
      const validationArry: boolean[] = []
      Object.keys(val).forEach((el: string) => {
        if (
          (val[el] !== null && val[el] !== '' && !Array.isArray(val[el]) && el !== 'sort') ||
          // eslint-disable-next-line no-prototype-builtins
          (Array.isArray(val[el]) && val[el].length && ((!val[el][0].hasOwnProperty('checked') && val[el][0] !== '') || this.checkIfAnySelectedCheckbox(val[el])))
        ) {
          validationArry.push(true)
        } else {
          validationArry.push(false)
        }
        // Set form values to route
      })
      this.formHasValue = validationArry.includes(true)
    },
    onFilterTextChange: debounce(function (this: any, searchTerm: string, formItem: any) {
      this.form[formItem.model] = searchTerm
    }, 500)
  },
  computed: {
    componentValidParams (): any {
      return this.filters ? this.filters.map((filter: any) => filter.model) : []
    }
  }
})
